/*
     PRODUCTION

 */

     export const DOMAIN_URL_US = 'https://money2india.icicibank.com/M2IUS/m2ipwa';
     export const DOMAIN_URL_NON_US = 'https://money2india.icicibank.com/M2IROW/m2ipwa';
     export const DOMAIN_URL = 'https://money2india.icicibank.com';
     export const DOMAIN_URL_ORP_CANADA = 'https://www.money2india.icicibank.ca/';
     export const DOMAIN_URL_ORP_UK = 'https://www.money2india.icicibank.co.uk/';
     export const DOMAIN_URL_EUROPE = '';
     export const DOMAIN_URL_SINGAPORE = '';
     export const DOMAIN_URL_HONGKONG = '';
     export const OLD_WEBSITE_LINK = 'https://money2india.icicibank.com/newm2iNet/';
     export const OLD_WEBSITE_LINK_SINGAPORE = 'https://money2india.icicibank.com/newm2iNetSIN/';
     export const CONTACT_PAGE = 'https://money2india.icicibank.com/usa/contact-us';
     export const CONTACT_US_LINK = 'https://money2india.icicibank.com/contact-us';
     export const GA_CONTAINER_ID = 'GTM-PRHNF8';
     export const GA_TRACKING_ID = 'UA-17882001-2';
     export const REFER_FRIEND_NLI = 'https://money2india.icicibank.com/refer-friend-NLI';
     export const HOME_PAGE = 'https://money2india.icicibank.com/';
     export const CLOUD_API = 'https://us-central1-tsg5-m2i-ui-ux-revamp.cloudfunctions.net/Geolocation-M2I';
     export const ISENCRYPTION_REQUIRED = true;
     // /*
     //     CUG
     
     // */
     
    //  export const DOMAIN_URL_US = 'https://money2indiacug.icicibank.com/newm2iNetUSCUG/m2ipwa';
    //  export const DOMAIN_URL_NON_US = 'https://money2indiacug.icicibank.com/newm2iNetUSCUG/m2ipwa';
    //  export const DOMAIN_URL = 'https://money2indiauat.icicibank.com';
    //  export const DOMAIN_URL_ORP_CANADA = 'https://orpuattest.icicibank.com/';
    //  export const DOMAIN_URL_ORP_UK = 'https://orpuat.icicibank.com/';
    //  export const DOMAIN_URL_EUROPE = '';
    //  export const DOMAIN_URL_SINGAPORE = '';
    //  export const DOMAIN_URL_HONGKONG = '';
    //  export const OLD_WEBSITE_LINK = 'https://money2india.icicibank.com/newm2iNet/';
    //  export const OLD_WEBSITE_LINK_SINGAPORE = 'https://money2india.icicibank.com/newm2iNetSIN/';
    //  export const CONTACT_PAGE = 'https://money2indiacug.icicibank.com/usa/contact-us';
    //  export const GA_CONTAINER_ID = 'GTM-PRHNF8';
    //  export const GA_TRACKING_ID = 'UA-17882001-2';
    //  export const REFER_FRIEND_NLI = 'https://money2indiacug.icicibank.com/refer-friend-NLI';
    //  export const HOME_PAGE = 'https://money2indiacug.icicibank.com/';
    //  export const CLOUD_API = 'https://us-central1-tsg5-m2i-ui-ux-revamp.cloudfunctions.net/Geolocation-M2I';
    //  export const ISENCRYPTION_REQUIRED = true;
     
     // /*
     //     UAT
     
     // */
     
    //  export const DOMAIN_URL_US = 'https://money2indiauat.icicibank.com/newm2iNetUS_NIVEUS_UAT/m2ipwa';
    //  export const DOMAIN_URL_NON_US = 'https://money2indiauat.icicibank.com/newm2iNetOthr_NIVEUS_UAT/m2ipwa';
    //  export const DOMAIN_URL = 'https://money2indiauat.icicibank.com';
    //  export const DOMAIN_URL_ORP_CANADA = 'https://orpuattest.icicibank.com/';
    //  export const DOMAIN_URL_ORP_UK = 'https://orpuat.icicibank.com/';
    //  export const DOMAIN_URL_EUROPE = '';
    //  export const DOMAIN_URL_SINGAPORE = '';
    //  export const DOMAIN_URL_HONGKONG = '';
    //  export const OLD_WEBSITE_LINK = 'https://m2istagingweb.icicibank.com/newm2iNet/';
    //  export const OLD_WEBSITE_LINK_SINGAPORE = 'https://money2india.icicibank.com/newm2iNetSIN/';
    //  export const CONTACT_PAGE = 'https://money2indiauat.icicibank.com/usa/contact-us';
    //  export const CONTACT_US_LINK = 'https://money2indiauat.icicibank.com/contact-us';
    //  export const GA_CONTAINER_ID = 'GTM-PRHNF8';
    //  export const GA_TRACKING_ID = 'UA-17882001-2';
    //  export const REFER_FRIEND_NLI = 'https://money2indiauat.icicibank.com/refer-friend-NLI';
    //  export const HOME_PAGE = 'https://money2indiauat.icicibank.com/';
    //  export const CLOUD_API = 'https://us-central1-tsg5-m2i-ui-ux-revamp.cloudfunctions.net/Geolocation-M2I';
    //  export const ISENCRYPTION_REQUIRED = true;
     
     // /*
     
     //  /*
     
     //     STAGING
     
     // */
     
     // export const DOMAIN_URL_US = 'https://money2indiauat.icicibank.com:7443/newm2iNetUS_NIVEUS_STAGING/m2ipwa';
     // export const DOMAIN_URL_NON_US = 'https://money2indiauat.icicibank.com:7443/newm2iNetOthr_ROWNIVEUS_STAGING/m2ipwa';
     // export const DOMAIN_URL = 'https://money2indiauat.icicibank.com';
     // export const DOMAIN_URL_ORP_CANADA = 'https://orpuattest.icicibank.com/';
     // export const DOMAIN_URL_ORP_UK = 'https://orpuat.icicibank.com/'; 
     // export const DOMAIN_URL_EUROPE = '';
     // export const DOMAIN_URL_SINGAPORE = '';
     // export const DOMAIN_URL_HONGKONG = '';
     // export const OLD_WEBSITE_LINK = 'https://m2istagingweb.icicibank.com/newm2iNet/';
     // export const OLD_WEBSITE_LINK_SINGAPORE = 'https://money2india.icicibank.com/newm2iNetSIN/';
     // export const CONTACT_PAGE = 'https://money2indiauat.icicibank.com:7443/usa/contact-us';
     // export const CONTACT_US_LINK = 'https://money2indiauat.icicibank.com:7443/contact-us';
     // export const GA_CONTAINER_ID = 'GTM-PRHNF8';
     // export const GA_TRACKING_ID = 'UA-17882001-2';
     // export const REFER_FRIEND_NLI = 'https://money2indiauat.icicibank.com:7443/refer-friend-NLI';
     // export const HOME_PAGE = 'https://money2indiauat.icicibank.com:7443/';
     // export const CLOUD_API = 'https://us-central1-tsg5-m2i-ui-ux-revamp.cloudfunctions.net/Geolocation-M2I';
     // export const ISENCRYPTION_REQUIRED = true;
     // /*
     
     //  /*
     
     //     STAGING
     
     // */
     
     // export const DOMAIN_URL_US = 'https://money2indiauat.icicibank.com/newm2iNetUS_NIVEUS_STAGING/m2ipwa';
     // export const DOMAIN_URL_NON_US = 'https://money2indiauat.icicibank.com/newm2iNetOthr_ROWNIVEUS_STAGING/m2ipwa';
     // export const DOMAIN_URL = 'https://money2indiauat.icicibank.com';
     // export const DOMAIN_URL_ORP_CANADA = 'https://orpuattest.icicibank.com/';
     // export const DOMAIN_URL_ORP_UK = 'https://orpuat.icicibank.com/';
     // export const DOMAIN_URL_EUROPE = '';
     // export const DOMAIN_URL_SINGAPORE = '';
     // export const DOMAIN_URL_HONGKONG = '';
     // export const OLD_WEBSITE_LINK = 'https://m2istagingweb.icicibank.com/newm2iNet/';
     // export const OLD_WEBSITE_LINK_SINGAPORE = 'https://money2india.icicibank.com/newm2iNetSIN/';
     // export const CONTACT_PAGE = 'http://34.69.201.187:3000/usa/contact-us';
     // export const CONTACT_US_LINK = 'http://34.69.201.187:3000/contact-us';
     // export const GA_CONTAINER_ID = 'GTM-PRHNF8';
     // export const GA_TRACKING_ID = 'UA-17882001-2';
     // export const REFER_FRIEND_NLI = 'http://34.69.201.187:3000/refer-friend-NLI';
     // export const HOME_PAGE = 'http://34.69.201.187:3000/';
     // export const CLOUD_API = 'https://us-central1-tsg5-m2i-ui-ux-revamp.cloudfunctions.net/Geolocation-M2I';
     // export const ISENCRYPTION_REQUIRED = false;
     // /*
     //     DEV
     
     // */
     
     // export const DOMAIN_URL_US = 'https://money2indiauat.icicibank.com/newm2iNetUS_NIVEUS_DEV/m2ipwa';
     // export const DOMAIN_URL_NON_US = 'https://money2indiauat.icicibank.com/newm2iNetOthr_NIVEUS_DEV/m2ipwa';
     // export const DOMAIN_URL = 'https://money2indiauat.icicibank.com';
     // export const DOMAIN_URL_ORP_CANADA = 'https://orpuattest.icicibank.com/';
     // export const DOMAIN_URL_ORP_UK = 'https://orpuat.icicibank.com/'; 
     // export const DOMAIN_URL_EUROPE = '';
     // export const DOMAIN_URL_SINGAPORE = '';
     // export const DOMAIN_URL_HONGKONG = '';
     // export const OLD_WEBSITE_LINK = 'https://m2istagingweb.icicibank.com/newm2iNet/';
     // export const OLD_WEBSITE_LINK_SINGAPORE = 'https://money2india.icicibank.com/newm2iNetSIN/';
     // export const CONTACT_PAGE = 'http://34.69.201.187:3000/contact-us';
     // export const CONTACT_US_LINK = 'http://34.69.201.187:3000/contact-us';
     // export const GA_CONTAINER_ID = 'GTM-PRHNF8';
     // export const GA_TRACKING_ID = 'UA-17882001-2';
     // export const REFER_FRIEND_NLI = 'http://localhost:3000/refer-friend-NLI';
     // export const HOME_PAGE = 'http://localhost:3000/';
     // export const CLOUD_API = 'https://us-central1-tsg5-m2i-ui-ux-revamp.cloudfunctions.net/Geolocation-M2I';
     // export const ISENCRYPTION_REQUIRED = false;
     
